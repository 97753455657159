@use 'functions' as *;

@import './material.scss';
@import './global.scss';
@import './styles/buttons.scss';
@import './styles/form.scss';
@import './styles/helpers.scss';

//#region Sceleton
.skeleton,
.skeleton-frame {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: .7;
    pointer-events: none !important;
    outline: none !important;
    border-radius: var(--border-radius);
    color: transparent !important;

    &::before {
        content: 'L';
        position: relative;
        color: transparent;
        text-overflow: clip;
    }
}

.skeleton {
    border: none !important;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-frame {
    border: px2rem(2) solid;
    animation: skeleton-frame-loading 1s linear infinite alternate;
}

.skeleton-section {
    gap: var(--gap05);

    .skeleton {
        height: auto;
    }
}

@keyframes skeleton-loading {
    0% {
        background-color: var(--cl-bg-panel-sec);
        fill: var(--cl-bg-panel-sec);
    }

    100% {
        background-color: var(--cl-bg-panel-fourth);
        fill: var(--cl-bg-panel-fourth);
    }
}

@keyframes skeleton-frame-loading {
    0% {
        border-color: var(--cl-bg-panel-sec);
        stroke: var(--cl-bg-panel-sec);
    }

    100% {
        border-color: var(--cl-bg-panel-fourth);
        stroke: var(--cl-bg-panel-fourth);
    }
}

@keyframes skeleton-blinking {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

//#endregion Sceleton