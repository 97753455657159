@use "sass:map";

@mixin theme($colors) {

    @each $name,
    $color in $colors {
        --cl-#{$name}: #{$color};

        @if str-index($name, "bg-")==0 {
            .background-#{$name} {
                background: var(--cl-#{$name});
            }
        }

        @else {
            @if str-index($name, "text-")==0 {
                .color-#{$name} {
                    color: var(--cl-#{$name});
                }
            }
            @else {
                @if std-index($name, "accent-") {
                    .color-#{$name} {
                        color: var(--cl-#{$name});
                    }
                    .background-#{$name} {
                        background: var(--cl-#{$name});
                    }
                }
            }
        }
    }
}