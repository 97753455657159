@use "sass:map";

@mixin rig-states($colors) {
    @each $name, $color in $colors {
        --rs-#{$name}: #{$color};

        .rs-#{$name} {
            color: #{$color};
        }
    }
}