.fieldset {
    display: flex;
    flex-direction: column;
    gap: var(--gap05);
    margin: 0;
    padding: 0;
    border: 0;

    .label {
        @include font-size(14);
        font-variant: all-small-caps;
        color: var(--cl-text-enable-sec);
    }
}

.textarea {
    inline-size: 100%;
    block-size: px2rem(100);
    padding: var(--gap) var(--gap15);
    border: 0;
    border-radius: var(--border-radius);
    background-color: var(--cl-bg-main);
    color: var(--cl-text-button-active);
    outline: none;
    resize: none;
    font-family: inherit;

    &::placeholder {
        color: var(--cl-text-disable);
    }
}