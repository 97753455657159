// Common button styles
.cl-button,
.cl-outlined-button,
.cl-primary-button,
.cl-flat-button,
.cl-icon-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    block-size: px2rem(34);
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;

    &:hover:not([disabled]) {
        opacity: 0.7;
        cursor: pointer;
    }

    &:focus-visible {
        outline: px2rem(2) solid var(--cl-accent-3);
    }

    &[disabled] {
        opacity: 0.8;
        cursor: not-allowed;
    }
}

// Text button (with icon)
.cl-button,
.cl-outlined-button,
.cl-primary-button,
.cl-flat-button {
    padding-inline: var(--gap);
    gap: var(--gap);
    border-radius: var(--border-radius);
    white-space: nowrap;
    @include font-size(15);
    font-family: inherit;
    font-variant: all-small-caps;
    color: var(--cl-text-enable-sec);

    &,
    &>.label {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.cl-button {
    &.active {
        font-weight: 600;

        &,
        icon {
            color: var(--cl-text-active);
        }
    }

    icon {
        --icon-size: #{px2rem(18)};
        color: var(--cl-text-enable-prim);
    }
}

.cl-outlined-button {
    border: px2rem(1) solid var(--cl-divider);

    &.active {
        border-color: var(--cl-text-button-active);
        outline: px2rem(1) solid var(--cl-text-button-active);

        &,
        icon {
            color: var(--cl-text-button-active);
        }
    }
}

.cl-flat-button {
    //TODO: this is a temporary fix for the light theme. Should be changed to the correct color as soon as the design is corrected.
    background-color: var(--cl-divider);
}

.cl-primary-button,
.cl-flat-button.active {
    background-color: var(--cl-text-active);
    color: var(--cl-text-button-active);
}

// Icon button
.cl-icon-button {
    --icon-size: #{px2rem(18)};
    inline-size: px2rem(34);
    border-radius: 50%;
    color: var(--cl-text-enable-prim);

    &.outlined {
        border: px2rem(1) solid var(--cl-divider);
    }
}