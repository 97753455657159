@use 'functions' as *;

@import "./fonts.scss";

@import './highcharts.scss';

@import './themes/index.scss';
@import './themes/dark.scss';
@import './themes/light.scss';
@import './themes/rig-states/index.scss';
@import './themes/rig-states/dark.scss';
@import './themes/rig-states/light.scss';

@import './themes/rig-activity/index.scss';
@import './themes/rig-activity/dark.scss';
@import './themes/rig-activity/light.scss';

@import './themes/traces/index.scss';
@import './themes/traces/traces-color.scss';


@import 'ngx-toastr/toastr';

:root {
    --gap05: #{px2rem(5)};
    --gap: #{px2rem(10)};
    --gap15: #{px2rem(15)};
    --gap2: #{px2rem(20)};
    --gap3: #{px2rem(30)};
    --border-radius: #{px2rem(6)};

    --animation-duration: .3s;
    --animation-duration--fast: .1s;

    --animation-easing: cubic-bezier(0.65, 0.05, 0.36, 1);

    --animate-duration: var(--animation-duration);

    --icon-size: #{px2rem(18)};
}

*,
*::before,
*::after {
    box-sizing: border-box;
    user-select: none;
}

html,
body {
    min-width: px2rem(320);
    height: 100%;
    overflow: hidden;
}

@mixin std-font() {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
}

body {
    @include init_px2rem();
    @include theme($dark-theme);
    @include rig-states($rig-state--dark);
    @include rig-activity($rig-activity--dark);
    @include std-font();
    @include traces($traces--all);

    overflow: hidden;
    margin: 0;

    @mixin scroll {
        $scroll-size: px2rem(4);

        ::-webkit-scrollbar {
            height: $scroll-size;
            width: $scroll-size;
        }

        ::-webkit-scrollbar-track {
            background: var(--cl-bg-panel-sec);
            border-radius: 100vw;
        }

        ::-webkit-scrollbar-thumb {
            background: var(--cl-text-disable);

            border-radius: 100vw;
        }

        @supports (scrollbar-color: red blue) {
            * {
                scrollbar-color: var(--text-disable) var(--cl-bg-panel-sec);
                scrollbar-width: $scroll-size;
            }
        }
    }

    &.light,
    &.theme-light,
    &[theme="light"] {
        @include theme($light-theme);
        @include rig-states($rig-state--light);
        @include rig-activity($rig-activity--light);
        @include scroll;

    }

    background: var(--cl-bg-main);
    color: var(--cl-text-enable-prim);

    transition: color var(--cl-animation-duration--fast),
    background var(--cl-animation-duration--fast);

    @include scroll;

    .toast-container .ngx-toastr {
        box-shadow: var(--cl-popup-shadow);
        border-radius: var(--border-radius);

        @include std-font();

        padding: var(--gap) var(--gap2);

        &:hover {
            box-shadow: var(--cl-popup-shadow);
        }

        &.toast-error {
            background: var(--cl-accent-5);
            color: var(--cl-text-enable-prim);
        }

        &.toast-info {
            background: var(--cl-accent-1);
            color: var(--cl-text-enable-prim);
        }

        &.toast-warning {
            background: var(--cl-accent-2);
            color: var(--cl-bg-panel-sec);
        }
    }
}

.failure {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 30vw;
    max-width: 80vw;
    background: var(--cl-bg-panel-sec);
    border-radius: var(--border-radius);
    box-shadow: var(--cl-popup-shadow);
    padding: var(--gap2);

    .glitchy-header {
        position: relative;
        font-weight: 900;

        font-size: px2rem(30);
        line-height: px2rem(35);
        font-variant: all-small-caps;
        text-transform: uppercase;

        --offset: 0.04em;
        --length: 700ms;

        margin-bottom: var(--gap2);

        .glitchy-entry {
            text-transform: uppercase;
        }


        .glitchy-entry:nth-child(1) {
            position: relative;
            color: var(--cl-accent-5);
            // transform: translate(var(--offset), calc(1.13 * var(--offset)));
            // animation: glitch calc(var(--length) * 1.6) infinite;
            // color: blue;
        }

        .glitchy-entry:nth-child(2) {
            position: absolute;
            top: 0;
            //  transform: translate(calc(-1 * var(--offset)), calc(-0.5 * var(--offset)));
            // animation: glitch var(--length) infinite;
            // color: green;
            color: var(--cl-accent-5);

            clip-path: polygon(0 0, 100% 0, 100% 48%, 0 53%);
        }

        .glitchy-entry:nth-child(3) {
            position: absolute;
            top: 0;
            color: var(--cl-accent-5);
            //animation: glitch calc(var(--length) * 0.75) infinite;

            clip-path: polygon(0 100%, 100% 100%, 100% 66%, 0 59%);
        }


        @keyframes glitch {
            0% {
                text-shadow:
                    0.05em 0 0 rgb(98, 189, 239, .75),
                    -0.05em -0.025em 0 rgb(255, 182, 97, .75),
                    -0.025rem 0.05em 0 rgb(34, 182, 146, .75);
            }

            14% {
                text-shadow:
                    0.05em 0 0 rgb(98, 189, 239, .75),
                    -0.05em -0.025em 0 rgb(255, 182, 97, .75),
                    -0.025rem 0.05em 0 rgb(34, 182, 146, .75);
            }

            15% {
                text-shadow:
                    -0.05rem -0.025em 0 rgb(98, 189, 239, .75),
                    0.025em 0.025em 0 rgb(255, 182, 97, .75),
                    -0.05em -0.05em 0 rgb(34, 182, 146, .75);
            }

            49% {
                text-shadow:
                    -0.05rem -0.025em 0 rgb(98, 189, 239, .75),
                    0.025em 0.025em 0 rgb(255, 182, 97, .75),
                    -0.05em -0.05em 0 rgb(34, 182, 146, .75);
            }

            50% {
                text-shadow:
                    0.025em 0.05 0 rgb(98, 189, 239, .75),
                    0.05em 0 0 rgb(255, 182, 97, .75),
                    0 -0.05em 0 rgb(34, 182, 146, .75);
            }

            99% {
                text-shadow:
                    0.025em 0.05 0 rgb(98, 189, 239, .75),
                    0.05em 0 0 rgb(255, 182, 97, .75),
                    0 -0.05em 0 rgb(34, 182, 146, .75);
            }

            100% {
                text-shadow:
                    -0.025em 0 0 rgb(98, 189, 239, .75),
                    -0.025em -0.025em 0 rgb(255, 182, 97, .75),
                    -0.025em -0.05em 0 rgb(34, 182, 146, .75);
            }
        }

    }

    .text {
        color: var(--cl-text-enable-prim);

        font-size: px2rem(16);
        line-height: px2rem(16);
    }

}

button {
    cursor: pointer;

    padding-inline: 0;
    padding-block: 0;
    margin-block: 0;
    margin-inline: 0;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    background: transparent; // FIXME having transparent bg is a bad thing to have
    outline: transparent;
    border: none;
    color: inherit;

    &:not([disabled]):hover,
    &:focus-visible {
        opacity: 0.7;
    }

    &[disabled] {
        cursor: not-allowed;
    }
}

.fullscreen {
    position: fixed;
    inset: 0;
    z-index: 11001;
    background-color: var(--cl-bg-main) !important;
    border-radius: 0 !important;
}

block-with-heading.fullscreen {
    background-color: var(--cl-bg-panel-prim) !important;
}

body:has(.fullscreen) nav.header {
    visibility: hidden;
}