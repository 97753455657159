@use '@angular/material' as mat;

@use 'functions' as *;

@import './material/create-theme.scss';

@import './themes/dark.scss';
@import './themes/light.scss';

@include mat.core();

$web-portal-dark-theme: mat.define-dark-theme(createTheme($dark-theme));
$web-portal-light-theme: mat.define-light-theme(createTheme($light-theme));

body {
    --mdc-typography-button-letter-spacing: normal;
    --mdc-shape-medium: var(--border-radius);
    --mdc-dialog-container-elevation: var(--cl-popup-shadow);
    --mdc-text-button-label-text-color: var(--cl-text-enable-sec);

    @include mat.all-component-themes($web-portal-dark-theme);


    &.light,
    &.theme-light {
        @include mat.all-component-colors($web-portal-light-theme);

    }

    .mat-mdc-table {
        background: var(--cl-bg-panel-fourth);

        .mat-mdc-header-cell,
        .mat-mdc-footer-cell,
        .mat-mdc-cell {
            color: var(--cl-text-enable-prim);
        }

        .mat-mdc-header-row,
        .mat-mdc-footer-row {
            height: px2rem(36);
            background: var(--cl-bg-panel-sec);

            .mat-mdc-header-cell,
            .mat-mdc-footer-cell {
                padding: 0 var(--gap2);
                font-weight: 400;
                font-size: px2rem(16);
                line-height: px2rem(19);
                font-variant: all-small-caps;
                border-bottom: none;
            }
        }

        .mat-mdc-row {
            height: px2rem(48);

            .mat-mdc-cell {
                padding: 0 var(--gap2);
                border-bottom-color: var(--cl-divider);
            }
        }
    }


    .mat-mdc-icon-button {
        overflow: hidden;
        border-radius: var(--border-radius);

        .mat-mdc-button-persistent-ripple {
            border-radius: var(--border-radius) !important;
        }
    }

    .mat-mdc-select-placeholder {
        color: var(--cl-text-disable);
    }

    .mat-mdc-select-panel {
        &.file-uploaded-panel {
            --mdc-shape-medium: var(--border-radius);
            background-color: var(--cl-bg-panel-sec);

            .mdc-list-item {
                flex-direction: column;
                min-height: auto;
                height: px2rem(38);

                &__primary-text {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: px2rem(15);
                    color: var(--cl-text-enable-sec);
                }

                &::after {
                    content: "";
                    width: 100%;
                    height: px2rem(1);
                    opacity: 0.25;
                    background-color: var(--cl-text-disable);
                }

                .mat-pseudo-checkbox {
                    display: none;
                }
            }
        }
    }

    .cdk-overlay-pane {
        justify-content: center;

        &:has(.cl-select-overlay) {
            width: initial !important;
        }
    }

    .cdk-overlay-container {
        $menu-item-height: 30;
        $menu-icon-height: 15;

        .mat-mdc-menu-panel {
            margin-top: px2rem(-36);
            margin-right: px2rem(1);

            background: var(--cl-bg-panel-sec);
            box-shadow: var(--cl-popup-shadow) !important;
            border-radius: var(--border-radius);

            .mat-mdc-menu-content {
                padding: 0;

                &>icon[name="dots__vertical"] {
                    width: px2rem(3);
                    height: px2rem($menu-icon-height);
                    position: absolute;
                    top: px2rem(($menu-item-height - $menu-icon-height) * .5);
                    right: px2rem(10);
                    pointer-events: none;
                    color: var(--cl-text-enable-prim);
                }
            }

            .mat-mdc-menu-content,
            .mat-mdc-menu-item .mdc-list-item__primary-text {
                font-size: px2rem(14);
                line-height: px2rem(16);
                text-transform: lowercase;
                font-variant: all-small-caps;
                color: var(--cl-text-enable-sec);
            }

            .mat-mdc-menu-item {
                min-height: px2rem($menu-item-height);
                padding-left: px2rem(10);
                padding-right: px2rem(30);

                icon {
                    width: px2rem(12);
                    height: px2rem(12);
                    vertical-align: bottom;
                    margin-bottom: px2rem(5);
                    margin-right: px2rem(4);
                }
            }
        }
    }

    .mat-mdc-select-panel {
        background-color: var(--cl-bg-panel-sec);
    }

    // Dropdown, Input
    .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill,
    .mat-mdc-form-field-type-mat-input.mat-form-field-appearance-fill {
        .mdc-text-field {
            padding: 0 px2rem(15);
            border-radius: var(--border-radius);
            cursor: pointer;

            .mat-mdc-form-field-flex {
                .mat-mdc-form-field-infix {
                    width: auto;
                    min-height: px2rem(40);
                    padding: px2rem(8) 0;
                }

                .mat-mdc-floating-label {
                    top: 50%;
                    color: var(--cl-text-disable);
                    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
                        color 150ms cubic-bezier(0.4, 0, 0.2, 1),
                        opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
                }
            }

            .mdc-line-ripple {
                display: none;
            }

            &.mdc-text-field--filled {
                &:not(.mdc-text-field--disabled) {
                    background-color: var(--cl-bg-main);
                }

                .mdc-floating-label--float-above {
                    transform: none;
                    opacity: 0;
                }
            }
        }
    }

    .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill {
        .mat-mdc-select-arrow-wrapper {
            display: none;
        }

        .mat-mdc-form-field-icon-suffix {
            width: px2rem(14);

            icon {
                width: 100%;
            }
        }
    }

    // mat-slide-toggle
    .mdc-form-field {
        .mdc-switch {
            width: px2rem(30) !important;

            .mdc-switch__track {
                border-radius: px2rem(20) !important;
                height: px2rem(18) !important;
            }

            .mdc-switch__handle {
                width: px2rem(14) !important;
                height: px2rem(14) !important;
                left: px2rem(3);
            }

            .mdc-switch__icons {
                display: none;
            }
        }
    }

    // mat-button-toggle

    .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
        border-left: none;
    }

    .mat-button-toggle-group {
        border-radius: var(--border-radius);
        border: none;

        .mat-button-toggle {
            margin-inline: 0;
            width: 50%;
            display: flex;

            color: var(--cl-text-enable-sec);
            background-color: var(--cl-bg-panel-sec);
            transition: background-color var(--animation-duration) linear,
                color var(--animation-duration) linear;


            &.mat-button-toggle-checked {
                color: var(--cl-text-button-active);
                background-color: var(--cl-text-active);
                font-weight: 600;
            }

            .mat-button-toggle-button {
                display: flex;
                justify-content: center;
                align-items: center;

                .mat-button-toggle-label-content {
                    line-height: px2rem(16.41);
                    font-size: px2rem(14);
                    text-transform: uppercase;
                }
            }
        }
    }

    // mat-select
    .mat-mdc-select-panel {
        background: var(--cl-bg-main);

        .mat-mdc-option {
            .mdc-list-item__primary-text {
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            &:not(.selected) {
                .mdc-list-item__primary-text {
                    color: var(--cl-text-enable-sec);
                }
            }

            &:hover:not(.mat-option-disabled),
            &:focus:not(.mat-option-disabled) {
                background-color: var(--cl-bg-panel-prim);
            }
        }
    }

    // mat-select change font size item in select panel and field in export popup
    .item {
        &.extend {
            .selector {
                .mat-mdc-select-value {
                    font-size: px2rem(14);
                    line-height: px2rem(16.1);
                }

                .mdc-list-item__primary-text {
                    font-family: Roboto;
                    font-size: px2rem(14);
                    line-height: px2rem(16.1);
                    font-variant: all-small-caps;
                }
            }
        }
    }

    // mat-menu
    .mat-mdc-menu-panel {
        background: var(--cl-bg-main);

        .mat-mdc-menu-item {
            background-color: none;

            .mdc-list-item__primary-text {
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                color: var(--cl-text-enable-sec);
            }

            &.selected {
                background-color: var(--cl-bg-panel-sec);

                .mdc-list-item__primary-text {
                    color: var(--cl-text-enable-prim);
                }
            }
        }
    }

    .mat-mdc-slide-toggle.mat-accent{
        --mdc-switch-selected-focus-state-layer-color: #{var(--cl-text-button-active)};
        --mdc-switch-selected-handle-color: #{var(--cl-text-button-active)};
        --mdc-switch-selected-hover-state-layer-color: #{var(--cl-text-button-active)};
        --mdc-switch-selected-pressed-state-layer-color: #{var(--cl-text-button-active)};
        --mdc-switch-selected-focus-handle-color: #{var(--cl-text-button-active)};
        --mdc-switch-selected-hover-handle-color:  #{var(--cl-text-button-active)};
        --mdc-switch-selected-pressed-handle-color: #{var(--cl-text-button-active)};
        --mdc-switch-selected-focus-track-color: #{var(--cl-text-active)};
        --mdc-switch-selected-hover-track-color: #{var(--cl-text-active)};
        --mdc-switch-selected-pressed-track-color:  #{var(--cl-text-active)};
        --mdc-switch-selected-track-color: #{var(--cl-text-active)};
    }

}
