@use "sass:map";

@mixin rig-activity($colors) {
    @each $name, $color in $colors {
        --ra-#{$name}: #{$color};

        .ra-#{$name} {
            color: #{$color};
        }
    }
}
