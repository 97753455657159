@use "sass:map";

@mixin traces($colors) {
    @each $name, $color in $colors {
        --trace-#{$name}: #{$color};

        .trace-#{$name} {
            color: #{$color};
        }
    }
}