@use "sass:math";

$default-font-size-px: 16;

@mixin init_px2rem() {
    html,body {
        font-size: $default-font-size-px*1px;
    }
}

@function px2rem($pxValue) {
    @return round(1000 * math.div($pxValue, $default-font-size-px)) * 0.001rem;
}


@function _getLineHeight($pxFontSize) {
    @if ($pxFontSize == 36) {
        @return px2rem(42);
    }

    @if ($pxFontSize == 25) {
        @return px2rem(29);
    }

    @if ($pxFontSize == 20) {
        @return px2rem(23);
    }

    @if ($pxFontSize == 19) {
        @return px2rem(22);
    }

    @if ($pxFontSize == 18) {
        @return px2rem(21);
    }

    @if ($pxFontSize == 16) {
        @return px2rem(19);
    }

    @if ($pxFontSize == 15) {
        @return px2rem(18);
    }

    @if ($pxFontSize == 12) {
        @return px2rem(14);
    }

    @return px2rem($pxFontSize * 7/6);
}

@mixin font-size($pxValue) {
    font-size: px2rem($pxValue);
    line-height: _getLineHeight($pxValue);
}
