@use 'functions'as *;
@import 'node_modules/highcharts/css/highcharts.scss';

.highcharts-container {
    font-family: Roboto;
    font-weight: 400;
    font-size: px2rem(14);
    text-transform: lowercase;
    font-variant: small-caps;
}

.highcharts-background {
    background: var(--cl-bg-panel-prim);
    fill: none;
}

.highcharts-title {
    fill: var(--cl-text-enable-prim);
    font-size: px2rem(16);
    text-transform: uppercase;
    font-variant: all-small-caps;
}

.highcharts-subtitle {
    fill: var(--cl-text-enable-sec);
    font-size: px2rem(14);
    text-transform: lowercase;
    font-variant: all-small-caps;
}

.highcharts-axis-title {
    font-family: 'Roboto';
    font-style: normal;
}

.highcharts-axis-labels {
    fill: var(--cl-text-enable-sec);

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
    }
}

$tick-color: var(--cl-text-enable-sec);
$minor-tick-size: px2rem(5);
$major-tick-size: px2rem(10);

.highcharts-grid {
    stroke: var(--cl-bg-panel-fourth) !important;

    .highcharts-minor-grid-line {
        stroke-width: 0;
    }

    .highcharts-grid-line {
        stroke-width: 0;
    }
}

.highcharts-tick,
.highcharts-minor-tick {
    stroke: var(--cl-text-enable-sec);
    stroke-width: px2rem(1) !important;
}

// .highcharts-xaxis {

//     .highcharts-minor-grid-line {
//         fill: $tick-color;
//         height: $minor-tick-size;
//     }
// }

// .highcharts-yaxis {



//     .highcharts-tick {
//         fill: $tick-color;
//         width: $major-tick-size;
//     }

//     .highcharts-minor-grid-line {
//         fill: $tick-color;
//         width: $minor-tick-size;
//     }
// }

.highcharts-point {
    &.highcharts-color-1 {
        fill: var(--cl-accent-2);
        stroke: var(--cl-accent-2);
    }

    &.highcharts-color-2 {
        fill: var(--cl-accent-4);
        stroke: var(--cl-accent-4);
    }

    &.highcharts-color-3 {
        fill: var(--cl-accent-5);
        stroke: var(--cl-accent-5);
    }

    &.highcharts-color-0 {
        fill: var(--cl-bg-panel-fourth);
        stroke: var(--cl-bg-panel-fourth);
    }
}

div.highcharts-tooltip {
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    font-weight: 400;
    font-size: px2rem(15);
    line-height: px2rem(18);

    // Content wrapper
    &>span {
        display: flex;
        align-items: center;
        padding: px2rem(6) var(--gap) px2rem(4);
        background-color: var(--cl-bg-panel-fourth);
        box-shadow: 0 px2rem(3) px2rem(5) rgba(0, 0, 0, 0.25);
        border-radius: var(--border-radius);

        .dot {
            display: block;
            margin-right: var(--gap);
            width: px2rem(8);
            height: px2rem(8);
            margin-top: px2rem(-2);
            border-radius: 50%;
            background-color: currentColor;
        }

        .title {
            margin-right: var(--gap);
        }

        .value {
            font-size: px2rem(14);
            line-height: px2rem(16);
            // font-variant: all-small-caps;
            color: var(--cl-text-enable-sec);
        }
    }
}