@font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    src: url(assets/fonts/icons.woff2) format('woff2');
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}


$fonts: (
    'Thin': 100,
    'Light': 200 300,
    'Regular': 400,
    'Medium': 500 600,
    'Bold': 700 800,
    'Black': 900
);
@each $name, $sizes in $fonts {
    @each $size in $sizes {
        @font-face {
            font-family: 'Roboto';
            src:
                url('assets/fonts/Roboto-#{$name}.woff2') format('woff2'),
                url('assets/fonts/Roboto-#{$name}.ttf') format('ttf');
            font-weight: $size;
            font-style: normal;
        }
        @font-face {
            font-family: 'Roboto';
            src:
                url('assets/fonts/Roboto-#{$name}Italic.woff2') format('woff2'),
                url('assets/fonts/Roboto-#{$name}Italic.ttf') format('ttf');
            font-weight: $size;
            font-style: italic;
        }
    }
}
